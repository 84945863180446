import styled from "styled-components";

const Container = styled.div`
    // min-height: 400px;
    width: 80%;

    margin: 10px 0;
    position: relative;
    // filter: blur(6px);
`

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(50px, auto);
    margin-bottom: 10px;
    // border: 1px solid black
`

const ProfilePicture = styled.div`
    // border: 1px solid red;
    grid-column: 1;
    grid-row: 1;

    height: 42px;
    width: 42px;

    border-radius: 50%;

    background-image: url(${props => props.img});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`

const Metadata = styled.div`
    // border: 1px solid red;
    grid-column: 2/7;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;

`

const Name = styled.div`
    // border: 1px solid red;
    font-weight: bold;
`

const TimeStamp = styled.div`
    // border: 1px solid red;
    font-size: 0.7em;
`

const Content = styled.div`
    grid-column: 2/7;
    grid-row: 2/7;
    // max-width: 3fr;
    // border: 1px solid red;
    // padding-top: 250px;

    background-image: url(${props => props.src});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`

const Comment = styled.div`
    // border: 1px solid red;
    grid-column: 2/7;
    // grid-row: 2/5;
`

const Message = styled.div`
    position: absolute;
    top: 150px;
    text-align: center;
    background-color: black;
    color: white;
    z-index: 10;
    padding: 20px;
    line-height: 2em;
`

export {
    Container,
    Wrapper,
    ProfilePicture,
    Metadata,
    Name,
    TimeStamp,
    Content,
    Comment,
    Message
}
import { useQuery } from 'react-query'

import {
    Container,
    ListContainer,
    Wrapper,
    Image,
    DescriptionWrapper,
    Description,
    Title,
    Date,
} from './styles'

const Feed = () => {
    // const arrayRange = (start, stop, step) =>
    // Array.from(
    //     { length: (stop - start) / step + 1 },
    //     (value, index) => start + index * step
    // );
    const images = [
        "01", "02", "03", "04", "05", "06", "07", "08", "09", "10",
        "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21"
    ]
    return (
        <Container initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }}>
            <ListContainer className="no-scroll">
            { 
                images.map(image => {
                    return (
                        <Wrapper key={image}>
                            <Image img={`/feed/${image}.jpg`} />
                        </Wrapper>
                    )
                }) 
            }
            </ListContainer>
        </Container>
    )
}

export default Feed
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Image, Wrapper } from './styles';

const Gallery = (props) => {
    return (
        <>
        <h2>Álbum de fotos 👇</h2>
        <Swiper
        spaceBetween={50}
        slidesPerView={1}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
        >
            { 
                props.items.map(image => {
                    return (
                        <SwiperSlide key={image} style={{display:'flex', justifyContent: 'flex-start'}}>
                        <Wrapper key={image}>
                            <Image img={`/feed/${image}.jpg`} />
                        </Wrapper>
                        </SwiperSlide>
                    )
                }) 
            }
        </Swiper>
        </>
    )
}
export default Gallery
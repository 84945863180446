import { useQuery } from 'react-query'

import {
    Container,
    ListContainer,
    Wrapper,
    Image,
    DescriptionWrapper,
    Description,
    Title,
    Date,
    Shadow,
    Button,
    // Arrow1,
    // Arrow2,
    // Arrow3
} from './styles'

import { BsCalendarEvent, BsSpotify } from 'react-icons/bs';
import { GiTicket } from 'react-icons/gi';

import { RiInstagramFill } from 'react-icons/ri'

import client from "../../../assets/service/client";
import Loader from '../../../assets/components/loader/Loader';
import Gallery from '../../../assets/components/gallery/Gallery';
import { Link } from 'react-router-dom';
import Action from '../../action/Action';

const EventList = ({isVisible}) => {
    const arrayRange = (start, stop, step) =>
    Array.from(
        { length: (stop - start) / step + 1 },
        (value, index) => start + index * step
    );

    const getEventList = async () => {
        return await client.get(`event/organizer/${process.env.REACT_APP_ORGANIZER_ID}`)
        .then(res => {
            return res.data.events
        })
        .catch(err => err.message)
    }

    const parseDate = (date) => {
        let d = new window.Date(date);
        return d.toLocaleString("pt-br", {
            month: "short", day:"numeric", weekday: "short"
        });
    }

    const parseTime = (date) => {
        let d = new window.Date(date);
        return d.toLocaleString("pt-br", {
            hour: "numeric", minute: "numeric"
        });
    }

    
    const { isLoading, isError, data: events, error } = useQuery('events', getEventList);

    return (
        <Container initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }}>
            <ListContainer className='no-scroll'>
                { 
                isLoading ? <Loader /> :
                isError ? <>ERROR</> :
                !events.length ? <>Nenhum evento encontrado</> :
                events.filter(e => e.id === 180).map(event => {
                    let link = event.alias.toString();
                    let imgKey = event.backgroundArtImage.key;
                    return (
                        <Wrapper key={event.id}>
                            <Image to={`/events/${link}`} img={`${process.env.REACT_APP_S3_URL}/${imgKey}`} />
                            <DescriptionWrapper>
                                <Description to={`/events/${link}`}>
                                    {
                                        event.id == 145 ?
                                        <Title>{event.name}</Title>
                                        :
                                        <Title style={{fontSize: '0.8em'}}>{event.name}</Title>
                                    }
                                    <Date><BsCalendarEvent style={{color: '#86A3B8'}}/> &nbsp; 4 de Novembro, às 21h</Date>
                                </Description>
                                <Link to={`/events/${link}`} style={{display: "flex", justifyContent: "space-between", width: "75px", textDecoration: "none", color: 'rgb(35,48,77)'}}>
                                    <span style={{fontSize: "1em"}}><GiTicket /></span>
                                    <span style={{fontSize: "0.5em", alignSelf: "flex-end", textAlign: "center"}}>Mais informações</span>
                                </Link>
                            </DescriptionWrapper>
                        </Wrapper>
                    )
                }) 
                }
                <Button to='reverse-no-terminal-maritimo/tickets'>Garanta seu Ingresso</Button>
                <div style={{width: "85%"}}>
                <iframe style={{"borderRadius": "12px"}} src="https://open.spotify.com/embed/playlist/1Ic9FOJ4h65EILINFIe8ad?utm_source=generator" width="100%" height="152" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                </div>
                <div style={{width: "85%"}}>
                <Gallery items={[
                    "01", "02", "03", "04", "05", "06", "07", "08", "09", "10",
                    "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21"
                ]}/>
                </div>
                <div style={{minHeight: "10px"}} />
                {/* <Action /> */}
            </ListContainer>
            {/* <Button to='reverse'>Garanta seu Ingresso</Button>
            <Arrow1 />
            <Arrow2 />
            <Arrow3 /> */}
            {/* <Shadow /> */}
        </Container>
    )
}

export default EventList
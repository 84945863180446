import React, { useRef, useState } from 'react';
import styled from 'styled-components';

const FormContainer = styled.div`
  max-width: 500px;
  min-width: 200px;
  width: 70%;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  background-color: white;
`;

const FieldSet = styled.fieldset`
  border: none;
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
`;

const Button = styled.button`
  padding: 10px 15px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

function PaymentForm(props) {
    const [formData, setFormData] = useState({
        payer: {
          document: '',
          fullName: '',
          email: '',
          phoneNumber: ''
        },
        address: {
          country: '',
          state: '',
          city: '',
          district: '',
          street: '',
          zipCode: '',
          number: ''
        },
        card: {
          holderName: '',
          number: '',
          expiration: '',
          cvv: ''
        },
        installments: ''
      });

      const scrollableRef = useRef(null);

      const handleChange = (section, key) => (e) => {
        setFormData({
          ...formData,
          [section]: {
            ...formData[section],
            [key]: e.target.value
          }
        });
      };
  
    const [currentStep, setCurrentStep] = useState(1);
  
    // Simple validation function. For production, you'd want more detailed checks.
    const isPayerInfoValid = () => {
      return Object.values(formData.payer).every(value => value !== '');
    };
  
    const isAddressInfoValid = () => {
      return Object.values(formData.address).every(value => value !== '');
    };
  
    const isCardInfoValid = () => {
      return Object.values(formData.card).every(value => value !== '') && formData.installments !== '';
    };
  
    const handleNext = () => {
      if (currentStep === 1 && isPayerInfoValid()) {
        setCurrentStep(2);
        scrollableRef.current.scrollTo({
            top: 0,
            behavior: 'smooth'
          })
      } else if (currentStep === 2 && isAddressInfoValid()) {
        setCurrentStep(3);
        scrollableRef.current.scrollTo({
            top: 0,
            behavior: 'smooth'
          })
      }
    };
  
    const handleBack = () => {
      if (currentStep > 1) {
        setCurrentStep(currentStep - 1);
        scrollableRef.current.scrollTo({
            top: 0,
            behavior: 'smooth'
          })
      }
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      if (isCardInfoValid()) {
        console.log('Form data submitted:', formData);
        // Here, send the data to your server or payment processor.
      }
    };
  
    return (
        <div ref={scrollableRef} style={{width: "100%", height: "100%", overflowY: "scroll", padding: "20px 0px"}}>
      <FormContainer>
        <form onSubmit={handleSubmit}>
          {currentStep === 1 && (
            <FieldSet>
              <h3>Payer Information</h3>
              <Label>Document</Label>
                <Input type="text" value={formData.payer.document} onChange={handleChange('payer', 'document')} />

                <Label>Full Name</Label>
                <Input type="text" value={formData.payer.fullName} onChange={handleChange('payer', 'fullName')} />

                <Label>Email</Label>
                <Input type="email" value={formData.payer.email} onChange={handleChange('payer', 'email')} />

                <Label>Phone Number</Label>
                <Input type="tel" value={formData.payer.phoneNumber} onChange={handleChange('payer', 'phoneNumber')} />
              <Button type="button" onClick={handleNext} disabled={!isPayerInfoValid()}>Next</Button>
            </FieldSet>
          )}
  
          {currentStep === 2 && (
            <FieldSet>
              <h3>Address</h3>
              <Label>Country</Label>
                <Input type="text" value={formData.address.country} onChange={handleChange('address', 'country')} />

                <Label>State</Label>
                <Input type="text" value={formData.address.state} onChange={handleChange('address', 'state')} />

                <Label>City</Label>
                <Input type="text" value={formData.address.city} onChange={handleChange('address', 'city')} />

                <Label>District</Label>
                <Input type="text" value={formData.address.district} onChange={handleChange('address', 'district')} />

                <Label>Street</Label>
                <Input type="text" value={formData.address.street} onChange={handleChange('address', 'street')} />

                <Label>Zip Code</Label>
                <Input type="text" value={formData.address.zipCode} onChange={handleChange('address', 'zipCode')} />

                <Label>Number</Label>
                <Input type="text" value={formData.address.number} onChange={handleChange('address', 'number')} />
              <div style={{width: "100%", display: "flex", justifyContent: "space-evenly"}}>
              <Button type="button" onClick={handleBack}>Back</Button>
              <Button type="button" onClick={handleNext} disabled={!isAddressInfoValid()}>Next</Button>
              </div>
            </FieldSet>
          )}
  
          {currentStep === 3 && (
            <FieldSet>
              <h3>Card Information</h3>
              <Label>Holder Name</Label>
            <Input type="text" value={formData.card.holderName} onChange={handleChange('card', 'holderName')} />

            <Label>Card Number</Label>
            <Input type="text" value={formData.card.number} onChange={handleChange('card', 'number')} />

            <Label>Expiration Date (MM/YYYY)</Label>
            <Input type="text" value={formData.card.expiration} onChange={handleChange('card', 'expiration')} />

            <Label>CVV</Label>
            <Input type="text" value={formData.card.cvv} onChange={handleChange('card', 'cvv')} maxLength="4" />
              
              <Label>Installments</Label>
              <Input type="number" value={formData.installments} onChange={(e) => setFormData({...formData, installments: e.target.value})} />
              <div style={{width: "100%", display: "flex", justifyContent: "space-evenly"}}>
              <Button type="button" onClick={handleBack}>Back</Button>
              <Button type="submit" disabled={!isCardInfoValid()}>Pay</Button>
              </div>
            </FieldSet>
          )}
        </form>
      </FormContainer>
      </div>
    );
  }
  
  export default PaymentForm;
  









// export default CardForm;
